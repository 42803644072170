<template src="./viewtransactions.html"></template>
<style scoped src="./viewtransactions.css">

</style>
<script>
import accountConstants from '@/constants/AccountConstants.vue'
import transactionService from '@/services/TransactionService.vue'
import transactionConstants from '@/constants/TransactionConstants.vue'
import Loading from 'vue-loading-overlay'
import moment from 'moment'
import { requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import utils from '@/utils/utils.vue'
import modal from '@/components/modal/notification/modal'
import jsonToExcel from 'vue-json-excel'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    components: {
        Loading, modal, jsonToExcel, pagination
    },
    data() {
        return {
            searchBy: '',
            transactionType: null,
            transactionStatus: null,
            selectedCountry: null,
            searchValue: '',
            transactionDate: null,
            transactionDateTo: null,
            countryList: accountConstants.countryList,
            transactionSummary: [],
            loaderVisible: false,
            errorMessages: applicationConstants.formsMessages,
            columns: ['DateTime', 'TransactionType', 'ParentReferenceNumber', 'ReferenceNumber', 'Sender', 'RecipientName', 'TransactionStatus', 'Amount', 'DeviceOrigin', 'ManuallyFunded','ClientIP', 'Action'],
            options: {
                headings: {
                    TransactionType: 'Trans Type',
                    ReferenceNumber: 'Ref No',
                    Sender: 'Sender',
                    RecipientName: 'Beneficiary Name',
                    ManuallyFunded: 'Manually Credited',
                    ParentReferenceNumber: 'Parent Reference Number'
                },
                visibleColumns: ['DateTime', 'TransactionType', 'ParentReferenceNumber', 'ReferenceNumber', 'Sender', 'RecipientName', 'TransactionStatus', 'Amount', 'DeviceOrigin', 'ClientIP', 'Action'],
            },
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: {
                'Amount': 'Amount',
                'DateTime': 'DateTime',
                'Recipient Name': 'RecipientName',
                'Parent Reference Number': 'ParentReferenceNumber',
                'Reference Number': 'ReferenceNumber',
                'Transaction Status': 'TransactionStatus',
                'Transaction Type': 'TransactionType',
                'Device Origin': 'DeviceOrigin',
                'Sender': 'Sender'
            },
            eftTransactionData: null,
            eftRemarks: '',
            enableLazyLoading: applicationConstants.enableLazyLoading
        }
    },
    created() {
        try {
            let filters = this.$route.params.filters?.viewTransactionsFilters || null
            if (filters) {
                this.searchBy = filters.searchBy ? filters.searchBy : ''
                this.searchValue = filters.searchValue ? filters.searchValue : ''
                this.transactionType = filters.transactionType ? filters.transactionType : null
                this.transactionStatus = filters.transactionStatus ? filters.transactionStatus : null
                this.selectedCountry = filters.country ? filters.country : null
                this.transactionDate = filters.fromDate ? filters.fromDate : null
                this.transactionDateTo = filters.toDate ? filters.toDate : null
                this.showTransaction()
            }
        } catch (error) {
            //
        }
    },
    methods: {
        async showTransaction() {
            try {
                let datechecker = true, dateValiadation = true, fDate = null, tDate = null
                this.transactionDateTo = this.transactionDateTo === '' ? null : this.transactionDateTo
                this.transactionDate = this.transactionDate === '' ? null : this.transactionDate
                if (this.transactionDate !== null && this.transactionDateTo !== null) {
                    fDate = utils.methods.LocalToUTC(moment(moment(this.transactionDate)._d).format('MM-DD-YYYY 00:00:00'))
                    tDate = utils.methods.LocalToUTC(moment(moment(this.transactionDateTo)._d).format('MM-DD-YYYY 23:59:59'))
                    datechecker = this.transactionDateTo < this.transactionDate ? false : true
                } else if ((this.transactionDateTo !== null && this.transactionDate === null) ||
                    (this.transactionDateTo === null && this.transactionDate !== null)) {
                    this.$v.transactionDate.$touch()
                    this.$v.transactionDateTo.$touch()
                    dateValiadation = false
                }
                if (this.searchBy !== '') {
                    this.$v.searchValue.$touch()
                    if (this.$v.searchValue.$invalid) {
                        return
                    }
                }
                if (datechecker && dateValiadation) {
                    this.loaderVisible = true
                    await transactionService.methods.GetTransactionSummary({
                        Country: this.selectedCountry,
                        CustomerEmail: this.searchBy === 'Sender Email' ? this.searchValue : null,
                        FundingSource: null,
                        ParentReferenceNumber: this.searchBy === 'Parent Reference Number' ? this.searchValue : null,
                        ReferenceNumber: this.searchBy === 'Reference Number' ? this.searchValue : null,
                        TransactionDate: fDate,
                        TransactionDateTo: tDate,
                        TransactionId: this.searchBy === 'TransactionId' ? this.searchValue : null,
                        TransactionStatus: this.transactionStatus,
                        TransactionType: this.transactionType,
                    })
                        .then(res => {
                            this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                            if (this.transactionSummary) {
                                this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                            }else {
                                this.transactionSummary = []
                            }
                            if (this.transactionType === '6') { //for adding Manually Credited column for EFT Transactions
                                this.options.visibleColumns.splice((this.options.visibleColumns.length - 1), 0, 'ManuallyFunded')
                                this.fields['Manually Credited'] = 'ManuallyFunded'
                            } else {
                                if((this.options.visibleColumns).includes('ManuallyFunded')) {
                                    (this.options.visibleColumns).splice((this.options.visibleColumns).indexOf('ManuallyFunded'), 1)
                                }
                                Object.keys(this.fields).includes('Manually Credited') ? delete this.fields['Manually Credited'] : null
                            }
                            this.enableLazyLoading ? this.$refs.paginationRef.selectedPage = 1 : this.$refs.vueClientTable.setPage(1)
                        })
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                    this.$v.$reset()
                } else {
                    if (!datechecker) {
                        this.showAlert(null, 'Todate must be greater than fromdate')
                    }
                }
                this.loaderVisible = false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        responseMapping(data) {
            if (data) {
                data = Array.isArray(data) ? data : [data]
                const reciptient = (recipt) => {
                    Object.values(recipt).map((el, key) => {
                        const Objectkey = Object.keys(recipt)[key]
                        recipt[Objectkey] = el._text ? el._text : null
                    })
                }
                data.map(reciptient)
            } else {
                data = []
            }
            return data
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        cancel() {
            this.searchBy = '',
                this.searchValue = '',
                this.transactionType = null,
                this.transactionStatus = null,
                this.selectedCountry = null,
                this.transactionDate = null
            this.transactionDateTo = null
            this.transactionId = null
            this.$v.$reset()
        },
        printTransaction(row) {
            try {
                this.$router.push({
                    name: 'PrintTransaction',
                    params: {
                        TransactionId: row.TransactionId,
                        ParentReferenceNumber: row.ParentReferenceNumber,
                        CustomerEmail: row.SenderEmail,
                        TransactionStatus: row.TransactionStatus,
                        viewTransactionsFilters: {
                            'searchBy': this.searchBy,
                            'searchValue': this.searchValue,
                            'transactionType': this.transactionType,
                            'transactionStatus': this.transactionStatus,
                            'country': this.selectedCountry,
                            'fromDate': this.transactionDate,
                            'toDate': this.transactionDateTo
                        }
                    }
                })
            } catch (error) {
                //
            }
        },
        async sendEmailInstructions(transaction) {
            try {
                this.$bvModal.msgBoxConfirm('Are you sure you want to send email instructions with Reference Number ' + (transaction.ReferenceNumber ? transaction.ReferenceNumber : ''))
                    .then(async result => {
                        if (result) {
                            if (transaction?.ReferenceNumber) {
                                this.loaderVisible = true
                                await transactionService.methods.sendEmailInstructions({
                                    ReferenceNumber: transaction.ReferenceNumber
                                }).then(() => {
                                    this.showAlert(1, 'Email Instructions sent successfully')
                                }).catch(err => {
                                    this.alertObject = err
                                    this.$bvModal.show(this.$refs.childModal.id)
                                })
                                this.loaderVisible = false
                            }
                        }
                    })
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async updateEFTTransaction() {
            try {
                if (this.eftRemarks.trim().length > 0) {
                    this.loaderVisible = true
                    await transactionService.methods.updateEFT(
                        {
                            'CustomerEmail': this.eftTransactionData.SenderEmail,
                            'ReferenceNumber': this.eftTransactionData.ReferenceNumber,
                            'Remarks': this.eftRemarks
                        }
                    ).then(async () => {
                        this.eftTransactionData = null
                        await this.showTransaction()
                        this.loaderVisible = false
                        this.showAlert(1, 'EFT Transaction Updated Successfully')
                    }).catch(err => {
                        this.loaderVisible = false
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                } else {
                    this.showAlert(null, 'Please enter Remarks')
                }
            } catch (error) {
                this.eftTransactionData = null
                this.loaderVisible = false
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)
            }
        },
        getRemarks(transactionData) {
            this.eftRemarks = ''
            this.$bvModal.show('remarksModal')
            this.eftTransactionData = transactionData
        },
        exportTableData() {
            if (this.transactionSummary.length > 0) {
                return this.mapConstants(this.responseMapping(JSON.parse(JSON.stringify(this.transactionSummary))))
            }
        },
        mapConstants(transactionSummary) {
            let reducer = (accumulator, currentValue) => {
                return (accumulator === currentValue?.Value) ? currentValue?.Text : accumulator
            }
            transactionSummary.forEach(el => {
                el.TransactionStatus = transactionConstants.transactionStatus.reduce(reducer, el.TransactionStatus)
                el.DeviceOriginId = el.DeviceOriginId === null || el.DeviceOriginId === undefined ? '4' : el.DeviceOriginId
                el.DeviceOrigin = accountConstants.deviceOrigin.reduce(reducer, el.DeviceOriginId)
                el.Sender = el.SenderName + '-' + el.SenderEmail
                el.DateTime = this.UTCtoLocal(el.DateTime)
            })
            return transactionSummary
        },
        UTCtoLocal (utcTime) {
            return utcTime? moment.utc(utcTime, 'MM/DD/YYYY hh:mm:ss').local().format("MM/DD/YYYY hh:mm:ss A"): null
        },
    },
    validations() {
        return {
            searchValue: {
                required: requiredIf(() => this.searchBy != '')
            },
            transactionDate: {
                required: requiredIf(() => this.transactionDateTo && !this.transactionDate)
            },
            transactionDateTo: {
                required: requiredIf(() => !this.transactionDateTo && this.transactionDate)
            }
        }
    },
    computed: {
        today: () => new Date(),
        dataTableData() {
            let transactionSummary = []
            if(this.transactionSummary && this.transactionSummary.length > 0) {
                if (this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    transactionSummary = this.responseMapping(JSON.parse(JSON.stringify(this.transactionSummary)).slice((selectedPage - 1) * 10, selectedPage * 10))
                } else {
                    transactionSummary = this.responseMapping(JSON.parse(JSON.stringify(this.transactionSummary)))
                } 
                transactionSummary = this.mapConstants(transactionSummary)
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return transactionSummary
        }
    }
}
</script>